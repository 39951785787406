import Tabular from './components/tabular';
import getEnvConfigData from './config/config';
import getTheme from './config/theme_and_styles';
import React, { Component } from 'react';
import { withAuthenticator  } from '@aws-amplify/ui-react';
import Amplify from 'aws-amplify';
import { ThemeProvider } from '@material-ui/core/styles';

let configData = getEnvConfigData();
let theme = getTheme();

Amplify.configure({
    Auth: {
        region: 'eu-west-1',
        userPoolId: configData['userpool_id'],
        userPoolWebClientId: configData['client_id'],
        mandatorySignIn: true,       
        authenticationFlowType: 'USER_SRP_AUTH',
        oauth: {
            domain: configData['domain'],
            scope: ['email', 'openid'],
            redirectSignIn: configData['redirect_url'],
            redirectSignOut: configData['redirect_url'],
            responseType: 'token'
        }
    }
});

class App extends Component {

    render() {
        return (
            <ThemeProvider theme={theme}>
                <div className='App'>
                    <Tabular/>
                </div>
            </ThemeProvider>            
        );
    }
}

export default withAuthenticator(App);
