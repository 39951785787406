import React from 'react';
import MUIDataTable from "mui-datatables";
import { useTheme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';

import format_number_percentage from '../utility/math';

export default function KPITable(props) {
    const theme = useTheme();
    let data = props.data;
    let years = props.years;

    let previousValue = 'N/A';

    const optionsPrognoses = {
        setCellHeaderProps: value => ({ style: { fontWeight: 'bold', color: theme.palette.primary.main } }),
        customBodyRender: (value, tableMeta, updateValue) => (
            <FormControlLabel
                control={<TextField value={value || ''} />}
                onFocus = {event => {
                    event.target.select();
                    previousValue = event.target.value;
                }}
                onBlur = {event => {
                        let result = value;
                        if (!result.includes('%'))
                            result = format_number_percentage(result);
                        updateValue(result);
                        let changedRows = tableMeta.tableData;
                        changedRows[tableMeta.rowIndex][tableMeta.columnIndex] = result;
                        let forceUpdate = (previousValue !== result);
                        props.onKpiUpdated(changedRows, forceUpdate);
                    }
                }
                onChange={event => updateValue(event.target.value)}
            />
        )
    };

    const optionsComments = {
        setCellHeaderProps: value => ({ style: { fontWeight: 'bold', color: theme.palette.primary.main } }),
        customBodyRender: (value, tableMeta, updateValue) => (
            <FormControlLabel
                control={<TextField value={value || ''} />}
                onFocus = {event => event.target.select()}
                onBlur = {event => {
                        let result = value;
                        updateValue(result);
                        let changedRows = tableMeta.tableData;
                        changedRows[tableMeta.rowIndex][tableMeta.columnIndex] = result;
                        props.onKpiUpdated(tableMeta.tableData, false);
                    }
                }        
                onChange={event => updateValue(event.target.value)}
            />
        )
    }

    const columns = [
        {
            name: 'KPI',
            options: {
                setCellHeaderProps: value => ({ style: { fontWeight: 'bold', color: theme.palette.primary.main } }),
            }
        },
        {
            name: years[0],
            options: optionsPrognoses
        },
        {
            name: years[1],
            options: optionsPrognoses
        },
        {
            name: years[2],
            options: optionsPrognoses
        },
        {
            name: 'Kommentarer ' + years[0],
            options: optionsComments
        },
        {
            name: 'Kommentarer ' + years[1],
            options: optionsComments
        },
        {
            name: 'Kommentarer ' + years[2],
            options: optionsComments
        }
    ];

    const options = {
        rowsPerPage: 5,
        selectableRows: 'none'
    };

    return (
        <MUIDataTable
            title={"Angi prognoser for KPI-er"}
            data={data}
            columns={columns}
            options={options}
        />
    )
};