
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Badge from '@material-ui/core/Badge';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import SaveIcon from '@material-ui/icons/Save';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore'; 
import InfoIcon from '@material-ui/icons/Info'; 
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { Auth } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import React, { useRef } from 'react';
import ClassicPrognose from './classic_prognose';
import PriceOptimization from './price_optimization';

var pjson = require('../../package.json');

const useStyles = makeStyles(theme => ({
    // this group of buttons will be aligned to the right side
    toolbarButtons: {
      marginLeft: 'auto',
    },
  }));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function Tabular(props) {
    const classes = useStyles();

    const [tabValue, setTabValue] = React.useState(0);
    const [anchorAccountMenu, setAnchorAccountMenu] = React.useState(null);
    const [anchorNavigationMenu, setAnchorNavigationMenu] = React.useState(null);
    const isAccountMenuOpen = Boolean(anchorAccountMenu);
    const isNavigationMenuOpen = Boolean(anchorNavigationMenu);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };    
    const handleAccountMenuOpen = (event) => {
        setAnchorAccountMenu(event.currentTarget);
    };
    const handleAccountMenuClose = () => {
        setAnchorAccountMenu(null);
    };
    const handleNavigationMenuOpen = (event) => {
        setAnchorNavigationMenu(event.currentTarget);
    };
    const handleNavigationMenuClose = () => {
        setAnchorNavigationMenu(null);
    };
    const handleSignOut = (event) => {
        Auth.signOut();
    };
    const [openAbout, setOpenAbout] = React.useState(false);
    const handleCloseAbout = () => {
        setOpenAbout(false);
    };

    const handleAboutApp = (event) => {
        handleNavigationMenuClose();
        setOpenAbout(true);
    };

    const handleSave = (event) => {
        if (tabValue === 0) {
            classicPrognoseChildRef.current.storePrognoses();
        }
        else if (tabValue === 1) {
            priceOptimizationChildRef.current.saveConstraints();
        }
        handleNavigationMenuClose();
    }
    const handleRestore = (event) => {
        if (tabValue === 0) {
            classicPrognoseChildRef.current.restorePrognoses();
        }
        else if (tabValue === 1) {
            priceOptimizationChildRef.current.restoreConstraints();
        }
        handleNavigationMenuClose();
    }
    const handleDownloadReport = (event) => {
        if (tabValue === 0) {
            classicPrognoseChildRef.current.downloadReport();
        }
        else if (tabValue === 1) {
            priceOptimizationChildRef.current.downloadData(false);
        }
        handleNavigationMenuClose();
    }
    const handleDownloadData = (event) => {
        if (tabValue === 0) {
            classicPrognoseChildRef.current.downloadData();
        }
        else if (tabValue === 1) {
            priceOptimizationChildRef.current.downloadData(true);
        }
        handleNavigationMenuClose();
    }

    const renderAccountMenu = (
        <Menu
            anchorEl={anchorAccountMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id='account-menu'
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isAccountMenuOpen}
            onClose={handleAccountMenuClose}
        >
            <MenuItem onClick={handleSignOut}>Logg ut</MenuItem>
        </Menu>
    );

    const renderNavigationMenu = (
        <Menu
            anchorEl={anchorNavigationMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id='navigation-menu'
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isNavigationMenuOpen}
            onClose={handleNavigationMenuClose}
        >

            <MenuItem onClick={handleSave}>           
                <ListItemIcon>
                    <SaveIcon fontSize="small" />
                </ListItemIcon>
                Lagre innstillinger
            </MenuItem>
            <MenuItem onClick={handleRestore}>
                <ListItemIcon>
                    <SettingsBackupRestoreIcon fontSize="small" />
                </ListItemIcon>
                Gjenopprett innstillinger
            </MenuItem>
            <Divider light/>
            <MenuItem onClick={handleDownloadReport}>
                <ListItemIcon>
                    <AssessmentIcon fontSize="small" />
                </ListItemIcon>
                Last ned rapport
            </MenuItem>
            <MenuItem onClick={handleDownloadData}>
                <ListItemIcon>
                    <CloudDownloadIcon fontSize="small" />
                </ListItemIcon>
                Last ned data
            </MenuItem>
            <Divider light/>
            <MenuItem onClick={handleAboutApp}>
                <ListItemIcon>
                    <InfoIcon fontSize="small" />
                </ListItemIcon>
                Om Theisen
            </MenuItem>
        </Menu>
    );

    const classicPrognoseChildRef = useRef();
    const priceOptimizationChildRef = useRef();

    return <div>
        <AppBar position="static">
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleNavigationMenuOpen}
                >
                    <MenuIcon />
                </IconButton>
                
                <Tabs value={tabValue} onChange={handleTabChange} textColor="secondary" indicatorColor="secondary" aria-label="simple tabs example">
                    <Tab label="Prognose" />
                    <Tab label="Prisoptimering" />
                </Tabs>

                <div className={classes.toolbarButtons}>
                    <Tooltip title="Varsler">
                        <IconButton color="inherit">
                            <Badge badgeContent={0} color="secondary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Konto">
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-haspopup="true"
                            onClick={handleAccountMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle/>
                        </IconButton>
                    </Tooltip>
                </div>                
            </Toolbar>            
        </AppBar>
        {renderNavigationMenu}
        {renderAccountMenu}
        <TabPanel value={tabValue} index={0}>
            <ClassicPrognose ref={classicPrognoseChildRef} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
            <PriceOptimization ref={priceOptimizationChildRef} />
        </TabPanel>
        <Dialog
            open={openAbout}
            onClose={handleCloseAbout}
        >
            <DialogTitle>{"Om Theisen"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Theisen v{pjson.version}. Copyright © 2020-2023 Fremtind Forsikring AS.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseAbout}>
                    Lukk
                </Button>
            </DialogActions>
        </Dialog>
    </div>
}