import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';

import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

import Axios from 'axios';

import Select from '@material-ui/core/Select';

import getEnvConfigData, { getAxiosPostConfig } from '../config/config';

import React, { useEffect } from 'react';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

function get_axios_instance() {
    return Axios.create({baseURL: getEnvConfigData()['backend_url'] });
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function RunBatchJobDialog(props) {

    const [open, setOpen] = React.useState(false);
    const [products, setProducts] = React.useState([]);
    const [productsStrict, setProductsStrict] = React.useState(false);
    const [months, setMonths] = React.useState([1, 1]);
    const [startDay, setStartDay] = React.useState(1);
    const [jobNameValid, setJobNameValid] = React.useState(false);
    const [jobDescription, setJobDescription] = React.useState('');
    const [jobName, setJobName] = React.useState('');
    const [productNames, setProductsNames] = React.useState([]);
    const [excludeBonusChange, setExcludeBonusChange] = React.useState(false);
    const [singelKunder, setSingelKunder] = React.useState(false);
    const [optimizer, setOptimizer] = React.useState(1);
    const [initCondition, setInitCondition] = React.useState('risk_adjusted');
    const [constraintType, setConstraintType] = React.useState(1);

    useEffect(() => {
        async function getProductNames() {
            const headers = await getAxiosPostConfig();
            const resp = await get_axios_instance().get('/get_list_gamma_products', headers);
            setProductsNames(resp.data);
        };
        getProductNames();
     }, [])   

    const handleChangeProducts = (event) => {
        const {
          target: { value },
        } = event;
        setProducts(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };

    const handleOptimizerChange = (event) => {
        setOptimizer(Number(event.target.value));
    }

    const handleInitConditionChange = (event) => {
        setInitCondition(event.target.value);
    }

    const handleConstraintTypeChange = (event) => {
        setConstraintType(Number(event.target.value));
    }

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleCloseCancel = () => {
        setOpen(false);
    }

    const handleMonthsChange = (event, newValue) => {
        setMonths(newValue);
    }
    
    const handleStartDayChange = (event, newValue) => {
        setStartDay(newValue);
    }

    const handleStrictProductChange = (event) => {
        setProductsStrict(event.target.checked);
    }

    const handleExcludeBonusChange = (event) => {
        setExcludeBonusChange(event.target.checked);
    }

    const handleSingelKunderChange = (event) => {
        setSingelKunder(event.target.checked);
    }

    const handleNameChange = (event) => {
        const nameRegex = /^[a-zA-Z0-9_]+$/;
        if (nameRegex.test(event.target.value) && event.target.value.length <= 40) {
            setJobNameValid(true);
        }
        else {
            setJobNameValid(false);
        }
        setJobName(event.target.value);
    }

    const handleDescriptionChange = (event) => {
        setJobDescription(event.target.value);
    }

    const handleCloseConfirm = () => {
        setOpen(false);
        const setMonths = new Set();
        for (var i = months[0]; i <= months[1]; ++i) {
            setMonths.add(i);
        }
        props.onConfirm(products, [...setMonths], startDay, productsStrict, excludeBonusChange, singelKunder, jobName, jobDescription, optimizer, initCondition, constraintType);
    }

    const generateMarks = (min, max, step = 1 ) => {
        let arr = []
        for (let i = min; i <= max; i += step) {
            arr.push({
                value: i,
                label: i
            });
        }
        return arr;
    }

    return (
        <div>
            <Button onClick={handleClickOpen} variant="contained" endIcon={<PlayArrowIcon />}>
                Start ny batch-jobb
            </Button>
            <Dialog open={open} fullWidth={true} maxWidth={"md"} onClose={handleCloseCancel} aria-labelledby="new-batch-job-dialog-title">
                <DialogTitle id="new-batch-job-dialog-title">Start ny batch-jobb</DialogTitle>
                <DialogContent>
                    <TextField 
                        id="text-name-batch-job"
                        label="Navn"
                        variant="outlined" value={jobName}
                        onChange={handleNameChange}                        
                        error={!jobNameValid}
                        helperText="Navn på batch-kjøring kan kun inneholde tall, bokstaver og underscore, og må være kortere enn 40 tegn."
                    />
                    <br></br>
                    <br></br>
                    <TextField id="text-description-batch-job" label="Beskrivelse av batch-kjøring" fullWidth variant="outlined" multiline rows={4} value={jobDescription} onChange={handleDescriptionChange}/>
                    <DialogContentText>Fornyelsesmåneder</DialogContentText>
                    <Slider
                        aria-labelledby="months-slider"
                        step={1.0}
                        min={1.0}
                        max={12.0}
                        marks={generateMarks(1, 12)}
                        value={months}
                        onChange={handleMonthsChange}
                        valueLabelDisplay="auto"
                    />
                    <DialogContentText>Forfallsdag fra og med</DialogContentText>
                    <Slider
                        aria-labelledby="months-slider"
                        step={1.0}
                        min={1.0}
                        max={30.0}
                        marks={generateMarks(1, 30, 2)}
                        value={startDay}
                        onChange={handleStartDayChange}
                        valueLabelDisplay="auto"
                    />
                    <DialogContentText>Produkter</DialogContentText>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <Select
                            labelId="products-checkbox-label"
                            id="products-checkbox"
                            multiple
                            value={products}
                            onChange={handleChangeProducts}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {productNames.map((name) => (
                                <MenuItem key={name} value={name}>
                                    <Checkbox checked={products.indexOf(name) > -1} />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <br/><br/>
                    <FormLabel component="legend">Spesialfiltre</FormLabel>
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox checked={productsStrict} onChange={handleStrictProductChange} />}
                            label="Ekskluder kunder med andre produkter"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={singelKunder} onChange={handleSingelKunderChange} />}
                            label="Singelkunder"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={excludeBonusChange} onChange={handleExcludeBonusChange} />}
                            label="Ekskluder bilavtaler med bonusendring"
                        />
                    </FormGroup>
                    <FormLabel component="legend">Valg av optimeringsalgoritme</FormLabel>
                    <RadioGroup aria-label="optimeringsalg" name="optimeringsalg" row value={optimizer} onChange={handleOptimizerChange}>
                        <FormControlLabel value={0} control={<Radio />} label="SciPy.Optimize" />
                        <FormControlLabel value={1} control={<Radio />} label="NLOpt" />
                    </RadioGroup>
                    <FormLabel component="legend">Valg av initialbetingelse for optimering</FormLabel>
                    <RadioGroup aria-label="initialbetingelse" name="initialbetingelse" row value={initCondition} onChange={handleInitConditionChange}>
                        <FormControlLabel value={'risk_adjusted'} control={<Radio />} label="Flatt påslag justert for risiko" />
                        <FormControlLabel value={'flat'} control={<Radio />} label="Flatt påslag" />
                        <FormControlLabel value={'simulated'} control={<Radio />} label="Simulert premie" />
                    </RadioGroup>
                    <FormLabel component="legend">Valg av vekstberegning</FormLabel>
                    <RadioGroup aria-label="vekstberegning" name="vekstberegning" row value={constraintType} onChange={handleConstraintTypeChange}>
                        <FormControlLabel value={0} control={<Radio />} label="Avgangssensitiv økning" />
                        <FormControlLabel value={1} control={<Radio />} label="Absolutt økning" />
                    </RadioGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm} disabled={!jobNameValid}>
                        Bekreft valg
                    </Button>
                    <Button onClick={handleCloseCancel}>
                        Avbryt
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}