import { Auth } from 'aws-amplify';

export default function handleError(error, notifyError = null) {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that fall's out of the range of 2xx
        console.log('Data: ' + error.response.data);
        if (notifyError) {
            if (error.response.data.message) {
                notifyError(error.response.data.message);
            }
            else {
                notifyError(error.response.data);
            }
        }
        console.log('Status: ' + error.response.status);
        console.log('Headers: ', error.response.headers);
        if (error.response.status === 401) {
            Auth.signOut();
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log('Request:' + error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error message: ' + error.message);
    }
};