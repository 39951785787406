import React, { useImperativeHandle, forwardRef, useRef, useEffect } from 'react';
import Axios from 'axios';

import getEnvConfigData, { getAxiosPostConfig } from '../config/config';
import ProductGroupPortfolioSelect from './product_group_portfolio_select';
import KPITable from './kpi_table';
import ResultsTable from './results_table';
import format_number_percentage, { format_number_significant, format_number_integer } from '../utility/math';

import handleError from '../utility/error_handling';
import MessageDialog from './message_dialog';

import { embed as BokehEmbed } from "@bokeh/bokehjs";
import CircularProgress from '@material-ui/core/CircularProgress';

const input_kpis = {
    'ANTALL': 'Antall',
    'SNITTPREMIE': 'Snittpremie',
    'SKADEFREKVENS_KJERNE': 'Skadefrekvens (kjerne)',
    'SNITTSKADE_KJERNE': 'Snittskade (kjerne)',
    'SKADEPROSENT_STOR': 'Skadeprosent (stor)'
};

export const graph_kpis = {
    'ANTALL': 'Antall',
    'BESTANDSPREMIE': 'Bestandspremie',
    'FASTSATT_ERSTATNING_KJERNE': 'Fastsatt erstatning (kjerne)',
    'FASTSATT_ERSTATNING_TOTAL': 'Fastsatt erstatning (total)',
    'IBNR_PROSENT': 'IBNR-prosent',
    'OPPTJENT_PREMIE': 'Opptjent premie',
    'SKADEFREKVENS_KJERNE': 'Skadefrekvens (kjerne)',
    'SKADEPROSENT_KJERNE': 'Skadeprosent (kjerne)',
    'SKADEPROSENT_STOR': 'Skadeprosent (stor)',
    'SKADEPROSENT_TOTAL': 'Skadeprosent (total)',
    'SNITTPREMIE': 'Snittpremie',
    'SNITTSKADE_KJERNE': 'Snittskade (kjerne)'
}

function get_axios_instance() {
    return Axios.create({baseURL: getEnvConfigData()['backend_url'] });
}

function get_prognose_dict(rows, prognose_years, include_comments = false) {
    const comment_prognose = ['comment_' + prognose_years[0], 'comment_' + prognose_years[1], 'comment_' + prognose_years[2]]
    let prognoses = {};
    const kpi_values = get_kpis_dict(rows);
    const kpi_comments = get_comments_dict(rows);
    for (var kpi in input_kpis) {
        const data = {
            [prognose_years[0]]: kpi_values[input_kpis[kpi]]['this_year'],
            [prognose_years[1]]: kpi_values[input_kpis[kpi]]['next_year'],
            [prognose_years[2]]: kpi_values[input_kpis[kpi]]['next_next_year']
        };
        const comment_data = {
            [comment_prognose[0]]: kpi_comments[input_kpis[kpi]]['this_year'],
            [comment_prognose[1]]: kpi_comments[input_kpis[kpi]]['next_year'],
            [comment_prognose[2]]: kpi_comments[input_kpis[kpi]]['next_next_year']
        };
        if (include_comments === true)
            prognoses[kpi] = Object.assign({}, data, comment_data);
        else
            prognoses[kpi] = data;
    }
    return prognoses;
}

function get_selected_kpi_types() {
    let kpi_types = [];
    for (var kpi in graph_kpis) {
        kpi_types.push(kpi);
    }
    return kpi_types;
}

function format_result(kpi, value) {
    if (kpi === 'ANTALL') {
        return format_number_integer(value);
    }
    else {
        return format_number_significant(value);
    }
}

async function update_results_table(rows, years, portfolio, product_group, setResultRows) {
    setResultRows([]); // First clear result rows...
    if (portfolio === '' || product_group === '')
        return; // Invalid data, bail out.

    let prognoses = get_prognose_dict(rows, years);
    let kpi_types = get_selected_kpi_types();

    get_axios_instance().post('/compute_prognostized_values', {
            'product_group': product_group,
            'portfolio': portfolio,
            'kpi_types': kpi_types,
            'prognoses': prognoses
        },
        await getAxiosPostConfig()
    ).then(resp => {
        const rows = [];
        for (var kpi in resp.data) {
            var kpi_name = graph_kpis[kpi];
            const row = [
                kpi_name,
                format_result(kpi, resp.data[kpi][years[0]]),
                format_result(kpi, resp.data[kpi][years[1]]),
                format_result(kpi, resp.data[kpi][years[2]]),
            ];
            rows.push(row);
        }
        setResultRows(rows);
    }).catch(error => {
        handleError(error);
    });
}

async function update_graphs(rows, years, portfolio, product_group, notifyFinished, notifyError) {
    document.getElementById('plots').innerHTML = ""; // Clear pre-existing

    if (portfolio === '' || product_group === '')
        return; // Invalid data, bail out.

    let prognoses = get_prognose_dict(rows, years);
    let kpi_types = get_selected_kpi_types();

    get_axios_instance().post('/plot_prognose_graphs', {
            'product_group': product_group,
            'portfolio': portfolio,
            'kpi_types': kpi_types,
            'prognoses': prognoses
        },
        await getAxiosPostConfig()
    ).then(resp => {
        BokehEmbed.embed_item(resp.data, 'plots');
        notifyFinished();
    }).catch(error => {
        handleError(error, notifyError);
        notifyFinished();
    });
}

function get_comments_dict(rows) {
    let comments = {};
    for (var idx in rows) {
        let row = rows[idx];
        comments[row[0]] = {};
        comments[row[0]]['this_year'] = row[4];
        comments[row[0]]['next_year'] = row[5];
        comments[row[0]]['next_next_year'] = row[6];
    }
    return comments;
}

function get_kpis_dict(rows) {
    let kpi_values = {};
    for (var idx in rows) {
        let row = rows[idx];
        kpi_values[row[0]] = {};
        kpi_values[row[0]]['this_year'] = parseFloat(row[1]);
        kpi_values[row[0]]['next_year'] = parseFloat(row[2]);
        kpi_values[row[0]]['next_next_year'] = parseFloat(row[3]);
    }
    return kpi_values;
}

function convert_prognose_data_to_table_rows(kpi_values, years) {
    const rows = [];
    for (var kpi in kpi_values) {
        var kpi_name = input_kpis[kpi];
        const row = [
            kpi_name,
            format_number_percentage(kpi_values[kpi][years[0]]),
            format_number_percentage(kpi_values[kpi][years[1]]),
            format_number_percentage(kpi_values[kpi][years[2]]),
            kpi_values[kpi]['comment_' + years[0]],
            kpi_values[kpi]['comment_' + years[1]],
            kpi_values[kpi]['comment_' + years[2]],
        ];
        rows.push(row);
    }
    return rows;
}

const ClassicPrognose = forwardRef((props, ref) => {
    const [prognoseYears, setPrognoseYears] = React.useState([]); // Initialize to empty
    const [prognoseRows, setPrognoseRows] = React.useState([]); // Initialize to empty
    const [prognoseVersion, setPrognoseVersion] = React.useState(''); // Initialize to empty
    const [prognoseVersions, setPrognoseVersions] = React.useState([]); // Initialize to empty
    const [resultRows, setResultRows] = React.useState([]); // Initialize to empty
    const [portfolio, setPortfolio] = React.useState(''); // Initialize to empty
    const [productGroup, setProductGroup] = React.useState('');
    const [isLoadingOrSaving, setIsLoadingOrSaving] = React.useState(false);
    const [openErrorAlert, setOpenErrorAlert] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('Noe gikk galt');

    ///////////////////
    // Need to use refs, see https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
    const portfolioRef = useRef(); portfolioRef.current = portfolio;
    const productGroupRef = useRef(); productGroupRef.current = productGroup;
    const prognoseRowsRef = useRef(); prognoseRowsRef.current = prognoseRows;
    ///////////////////

    const notifyErrorMessage = (message) => {
        setErrorMessage(message);
        setOpenErrorAlert(true);
    };

    useEffect(() => {
        const getYears = async () => {
            const headers = await getAxiosPostConfig();
            const years = await get_axios_instance().get('/get_prognoses_years', headers).catch(error => {
                if (error.response.status === 401 || error.response.status === 404 || error.response.status === 500) {
                    handleError(error, notifyErrorMessage);
                }
            });
            setPrognoseYears(years.data);
        };
        const getPrognoseVersions = async () => {
            const headers = await getAxiosPostConfig();
            const prognoseVersions = await get_axios_instance().get('/get_prognose_versions', headers).catch(error => {
                if (error.response.status === 401 || error.response.status === 404 || error.response.status === 500) {
                    handleError(error, notifyErrorMessage);
                }
            });
            const currentPrognoseVersion = await get_axios_instance().get('/get_current_prognose_version', headers).catch(error => {
                if (error.response.status === 401 || error.response.status === 404 || error.response.status === 500) {
                    handleError(error, notifyErrorMessage);
                }
            });        
            setPrognoseVersion(currentPrognoseVersion.data);
            setPrognoseVersions(prognoseVersions.data);
        }
        getYears();
        getPrognoseVersions();
     }, []);

    const getPrognoses = async (product_group, portfolio, prognose_years) => {
        get_axios_instance().post('/compute_prognoses', {
                'product_group': product_group,
                'portfolio': portfolio
            },
            await getAxiosPostConfig()
        ).then(resp => {
            setIsLoadingOrSaving(true);
            const new_rows = convert_prognose_data_to_table_rows(resp.data, prognose_years);
            setPrognoseRows(new_rows);
            update_results_table(
                new_rows,
                prognose_years,
                portfolio,
                productGroup,
                (rows) => setResultRows(rows)
            );
            update_graphs(
                new_rows,
                prognose_years,
                portfolio,
                product_group,
                () => setIsLoadingOrSaving(false),
                notifyErrorMessage
            );
        }).catch(error => {
            if (error.response.status === 401 || error.response.status === 404 || error.response.status === 500) {
                handleError(error, notifyErrorMessage);
            }
        }).finally(() => {
            setIsLoadingOrSaving(false);
        });
    };

    const handleProductGroupPortfolioChange = async (product_group, portfolio) => {        
        setPortfolio(portfolio);
        setProductGroup(product_group);
        getPrognoses(product_group, portfolio, prognoseYears);
    };
    
    const handlePrognoseVersionChange = async (prognose_version, product_group, portfolio) => {
        setIsLoadingOrSaving(true);
        setPrognoseVersion(prognose_version);
        await get_axios_instance().post('/set_prognose_version', {
                'prognose_version': prognose_version
            },
            await getAxiosPostConfig()).catch(error => {
            if (error.response.status === 401 || error.response.status === 404 || error.response.status === 500) {
                handleError(error, notifyErrorMessage);
            }
        });
        const years = await get_axios_instance().get('/get_prognoses_years', await getAxiosPostConfig()).catch(error => {
            if (error.response.status === 401 || error.response.status === 404 || error.response.status === 500) {
                handleError(error, notifyErrorMessage);
            }
        });
        setPrognoseYears(years.data);
        await getPrognoses(product_group, portfolio, years.data);
    }

    const handleKpiChange = (changedRows, forceUpdate) => {
        setPrognoseRows(changedRows);
        if (!forceUpdate)
            return;
        setIsLoadingOrSaving(true);
        update_results_table(changedRows, prognoseYears, portfolioRef.current, productGroupRef.current, (rows) => setResultRows(rows));
        update_graphs(
            changedRows,
            prognoseYears,
            portfolioRef.current,
            productGroupRef.current,
            () => setIsLoadingOrSaving(false),
            notifyErrorMessage
        );
    };

    useImperativeHandle(ref, () => ({
        async restorePrognoses() {
            await getPrognoses(productGroup, portfolio, prognoseYears);
        },
        async storePrognoses() {
            setIsLoadingOrSaving(true);
            const prognoses = get_prognose_dict(prognoseRows, prognoseYears);
            get_axios_instance().post('/save_prognoses', {
                'product_group': productGroup,
                'portfolio': portfolio,
                'prognoses': prognoses,
                }, await getAxiosPostConfig())
            .then(resp => {
                const tableName = resp['data']['table'];
                prognoseVersions.indexOf(tableName) === -1 ? prognoseVersions.push(tableName) : console.log('No new tables created.');
                setPrognoseVersion(tableName);
                setIsLoadingOrSaving(false);
            });
        },
        async downloadData() {
            console.log('Method downloadData() not implemented for ClassicPrognose');
        },
        async downloadReport() {
            console.log('Method downloadReport() not implemented for ClassicPrognose');
        }
    }));

    const handleCloseErrorAlert = () => {
        setOpenErrorAlert(false);
    };

    return (
        <div>
            <div>
                <ProductGroupPortfolioSelect
                    product_group={productGroup}
                    portfolio={portfolio}
                    prognose_version={prognoseVersion}
                    prognose_versions={prognoseVersions}
                    disabled={isLoadingOrSaving}
                    onProductGroupPortfolioChanged={handleProductGroupPortfolioChange}
                    onPrognoseVersionChanged={handlePrognoseVersionChange}
                />
                <KPITable
                    data={prognoseRows}
                    years={prognoseYears}
                    onKpiUpdated={handleKpiChange}
                />
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    {isLoadingOrSaving && <CircularProgress/> }
                </div>
                <ResultsTable
                    data={resultRows} 
                    years={prognoseYears}
                />
                <MessageDialog openMessageDialog={openErrorAlert} handleCloseMessageDialog={handleCloseErrorAlert} message={errorMessage}/>
            </div>
            <div id='plots' className='bk-root' />
        </div>
    );
});
export default ClassicPrognose;