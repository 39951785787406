import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    }
}));

export default function ProductGroupSelect(props) {
    const classes = useStyles();
    let product_group = props.product_group;
    let portfolio = props.portfolio;
    let prognose_version = props.prognose_version;
    let prognose_versions = props.prognose_versions;
    let disabled = props.disabled;

    const handleProductGroupChange = (event) => {
        props.onProductGroupPortfolioChanged(event.target.value, portfolio);
    };

    const handlePortfolioChange = (event) => {
        props.onProductGroupPortfolioChanged(product_group, event.target.value);
    };

    const handlePrognoseVersionChange = (event) => {
        props.onPrognoseVersionChanged(event.target.value, product_group, portfolio);
    };

    const product_group_list = [
        "ANSVAR",
        "ANSVAR NSF",
        "BEHANDLING",
        "BETALINGSFORSIKRING",
        "BIL BM",
        "BIL FLÅTE",
        "BIL PM",
        "BK BM",
        "BM MOTOR ØVRIG",
        "BOLIGSELGER",
        "CAMPINGVOGN",
        "FLÅTE ØVRIG",
        "FRITIDSBOLIG",
        "FRITIDSBÅT",
        "GJELDSFORSIKRING",
        "HJEM",
        "HJEM NSF",
        "HJEM SELVBÆRENDE",
        "HUS",
        "HUSDYR PM",
        "LANDBRUKSBYGG",
        "LASTEBIL",
        "LO FAVØR BARN",
        "LO FRITID",
        "MC",
        "MOPED",
        "NÆRINGSBÅT",
        "PM MOTOR ØVRIG",
        "PRODUKSJON",
        "REISE BM",
        "REISE INDIVIDUELL",
        "REISE KOL. IND.",
        "REISE KOL. OBLIG.",
        "REISE KREDITTKORT",
        "REISE NSF",
        "TRAKTOR",
        "TRANSPORT",
        "TRYGGHET",
        "ULYKKE",
        "ULYKKE NSF",
        "YRKESSKADE",
    ]

    const menuItems = product_group_list.map(function (item) {
        return <MenuItem value={item}>{item}</MenuItem>
    });
    const prognoseVersionItems = prognose_versions.map(function (item) {
        return <MenuItem value={item}>{item}</MenuItem>
    });

    return (
        <div>
            <FormControl className={classes.formControl}>
                <InputLabel id="select-produt-group-label">Produktgruppe</InputLabel>
                <Select
                    labelId="select-product-group-label"
                    disabled={disabled}
                    id="select-produt-group"
                    value={product_group}
                    onChange={handleProductGroupChange}
                >
                    {menuItems}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="select-portfolio-label">Portefølje</InputLabel>
                <Select
                    labelId="select-portfolio-label"
                    disabled={disabled}
                    id="select-portfolio"
                    value={portfolio}
                    onChange={handlePortfolioChange}
                >
                    <MenuItem value={"SB1"}>SB1</MenuItem>
                    <MenuItem value={"DNB"}>DNB</MenuItem>
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="select-prognose-version">Datasett</InputLabel>
                <Select
                    labelId="select-prognose-version-label"
                    disabled={disabled}
                    id="select-prognose-version"
                    value={prognose_version}
                    onChange={handlePrognoseVersionChange}
                >
                    {prognoseVersionItems}
                </Select>
            </FormControl>
        </div>
    )
}