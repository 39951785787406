import { Auth } from 'aws-amplify';

export const Stages = {
    DEV: 'development',
    PREPROD: 'qa',
    PROD: 'production'
}

export default function getEnvConfigData() {
    return {
        client_id: '4jo383afri3u9nsvnkge83dcpr',
        userpool_id: 'eu-west-1_W0cO3bOOIs',
        domain: 'fremtind-dataplattform-prod.auth.eu-west-1.amazoncognito.com',
        redirect_url: redirectUrl(),
        backend_url: backendUrl()
    }
}
export const getStage = () => {
    const host = window.location.hostname.replace('www.', '');
    switch (host) {
        case 'theisen.ip-labs-prod.aws.fremtind.no':
            return Stages.PROD
        case 'theisen.ip-preprod.aws.fremtind.no':
            return Stages.PREPROD
        default:
            return Stages.DEV
    }
}

const redirectUrl = () => {
    switch (getStage()) {
        case Stages.PROD:
            return 'https://www.theisen.ip-labs-prod.aws.fremtind.no';
        case Stages.PREPROD:
            return 'https://www.theisen.ip-preprod.aws.fremtind.no';
        default:
            return 'http://localhost:3000'
    }
}

const backendUrl = () => {
    switch (getStage()) {
        case Stages.PROD:
            return 'https://prognose.ip-labs-prod.aws.fremtind.no';
        case Stages.PREPROD:
            return 'https://prognose.ip-preprod.aws.fremtind.no';
        default:
            return 'http://localhost:5000'
    }
}

async function getOrRefreshCurrentSession() {
    try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        return cognitoUser.signInUserSession;
    }
    catch (e) {
        console.log('Token has expired', e);
        return null;
    }
}

export async function getAxiosPostConfig() {
    const currentSession = await getOrRefreshCurrentSession();
    if (!currentSession) {
        return null;
    }
    //Uncomment the line below to inspect the token
    //console.log(currentSession.getIdToken().getJwtToken())
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': currentSession.getIdToken().getJwtToken(),
            'AccessToken': currentSession.getAccessToken().getJwtToken()
        }
    };
}
