import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function MessageDialog(props) {
    return (
        <div>
            <Dialog
                open={props.openMessageDialog}
                onClose={props.handleCloseMessageDialog}
                aria-labelledby="message-dialog-title"
                aria-describedby="message-dialog-description"
            >
                <DialogTitle id="message-dialog-title">Melding</DialogTitle>
                <DialogContent>
                    <DialogContentText id="message-dialog-description">
                        {props.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleCloseMessageDialog}>
                        Lukk
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}