import React, { useState } from 'react';
import MUIDataTable from "mui-datatables";
import { useTheme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';


export default function ConstraintsTable(props) {
    const theme = useTheme();
    let data = props.data;
    const [open, setOpen] = React.useState(false);


    const optionsNumbers = {
        setCellHeaderProps: value => ({ style: { fontWeight: 'bold', color: theme.palette.primary.main } }),
        customBodyRender: (value, tableMeta, updateValue) => (
            <FormControlLabel
                control={<TextField value={value || ''} />}
                onFocus = {event => {
                    event.target.select();
                }}
                onBlur = {event => {
                        let result = value;
                        updateValue(result);
                        let changedRows = tableMeta.tableData;
                        changedRows[tableMeta.rowIndex][tableMeta.columnIndex] = result;                        
                            props.onInputChanged(changedRows);
                    }
                }
                onChange={event => updateValue(event.target.value)}
            />
        )
    };

    const [columns] = useState([
        {
            name: 'Produkt',
            options: {
                setCellHeaderProps: value => ({ style: { fontWeight: 'bold', color: theme.palette.primary.main } }),
            }
        },
        {
            name: 'Snittpremievekst',
            options: {
                ...optionsNumbers,
                'hint': 'Krav til snittpremievekst for angitt produkt'
            }
        },
        {
            name: 'Tillatt negativ forflytning fra initiell pris',
            options: {
                ...optionsNumbers,
                'hint': 'Positivt tall mellom 0 og 0.2. Optimeringen vil ikke justere de initielle faktorene mer enn dette i negativ retning.'
            }            
        },
        {
            name: 'Tillatt positiv forflytning fra initiell pris',
            options: {
                ...optionsNumbers,
                'hint': 'Positivt tall mellom 0 og 0.2. Optimeringen vil ikke justere de initielle faktorene mer enn dette i positiv retning.'
            }            
        }
    ]);

    const options = {
        selectableRows: 'none',
        rowsPerPage: 4,
        rowsPerPageOptions: [4, 8, 12],
    };

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleCloseCancel = () => {
        setOpen(false);
    }

    const handleCloseConfirm = () => {
        setOpen(false);
    }

    return (
        <div>
            <Button onClick={handleClickOpen} variant="contained">Juster innstillinger</Button>  
            <Dialog open={open} fullWidth={true} maxWidth={"md"} onClose={handleCloseCancel} aria-labelledby="batch-job-constraints-dialog-title">
                <DialogTitle id="batch-job-constraints-dialog-title">Betingelser for batch-kjøringer</DialogTitle>
                <DialogContent>
                    <MUIDataTable
                        title={"Angi betingelser for snittpremievekst og initialbetingelser"}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm}>
                        Bekreft valg
                    </Button>
                    <Button onClick={handleCloseCancel}>
                        Avbryt
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
};