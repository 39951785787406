import MUIDataTable from "mui-datatables";
import React from 'react';
import { useTheme } from '@material-ui/core/styles';


export default function ResultsTable(props) {
    let data = props.data;
    let years = props.years;
    const theme = useTheme();
    const columns = [
        {
            name: 'KPI',
            options: {
                setCellHeaderProps: value => ({ style: { fontWeight: 'bold', color: theme.palette.primary.main } }),
            }
        },
        {
            name: years[0],
            options: {
                setCellHeaderProps: value => ({ style: { fontWeight: 'bold', color: theme.palette.primary.main } }),
            }
        },
        {
            name: years[1],
            options: {
                setCellHeaderProps: value => ({ style: { fontWeight: 'bold', color: theme.palette.primary.main } }),
            }
        },
        {
            name: years[2],
            options: {
                setCellHeaderProps: value => ({ style: { fontWeight: 'bold', color: theme.palette.primary.main } }),
            }
        }
    ];

    const options = {
        rowsPerPage: 4,
        rowsPerPageOptions: [4, 8, 12],
        selectableRows: 'none'
    };

    return (
        <MUIDataTable
            title={"Prognostiserte resultater"}
            data={data}
            columns={columns}
            options={options}
        />
    )
}