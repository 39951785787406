import { createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';

export default function getTheme() {
    return createMuiTheme({
        palette: {
            primary: {
                main: green[300]
            },
            secondary: {
                main: grey[800]
            }
        }
    })
}
