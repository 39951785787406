import React, { useImperativeHandle, forwardRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Axios from 'axios';

import getEnvConfigData, { getAxiosPostConfig } from '../config/config';

import { embed as BokehEmbed } from '@bokeh/bokehjs';
import { format } from 'date-fns';

import BatchJobsTable from './batch_jobs_table';
import RunBatchJobDialog from './run_batch_job_dialog';
import ConstraintsTable from './constraints_table';
import MessageDialog from './message_dialog';
import CircularProgress from '@material-ui/core/CircularProgress';

import handleError from '../utility/error_handling';

function get_axios_instance() {
    return Axios.create({baseURL: getEnvConfigData()['backend_url'] });
}

async function update_plots(jobIds, setIsLoadingOrSaving, product = 'NULL_FILTER', kundesegment = 'NULL_FILTER', notifyMessage = null) {
    // Clear pre-existing plots
    [...Array(10).keys()].map(i => {
        document.getElementById('gammaPlot' + i).innerHTML = '';
    })
    const headers = await getAxiosPostConfig();
    if (jobIds.length > 0) {
        var request_string = '/get_graphs_gamma_batch_job?';
        for (const jobId of jobIds) {
            request_string += 'batch_job_id='+jobId+'&';
        }
        if (product !== 'NULL_FILTER') {
            request_string += '&product=' + product;
        }
        if (kundesegment !== 'NULL_FILTER') {
            request_string += '&kundesegment=' + kundesegment;
        }

        setIsLoadingOrSaving(true);
        get_axios_instance().get(
            request_string, headers
        ).then(resp => {
            var i = 0;
            for (const data of resp.data) {
                BokehEmbed.embed_item(data, 'gammaPlot' + i);
                ++i;
            }
        }).catch(error => {
            handleError(error, notifyMessage);
        }).finally(() => {
            setIsLoadingOrSaving(false);
        });
    }
}

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    horizontal: {
        display: 'flex',
        flexDirection: 'row'
    },
    button: {
        marginLeft: theme.spacing(5)
    },
    includeWhitespaces: {
        whiteSpace: 'pre'
    }
}));

const PriceOptimization = forwardRef((props, ref) => {
    const classes = useStyles();

    const [isLoadingOrSaving, setIsLoadingOrSaving] = React.useState(false);
    const [batchJobsData, setBatchJobsData] = React.useState([]);
    const [selectedBatchJobs, setSelectedBatchJobs] = React.useState([]);
    const [products, setProducts] = React.useState([]);
    const [kundesegmenter, setKundesegmenter] = React.useState(['NULL_FILTER', 'G01', 'STD', 'S01']); // TODO: Move this to backend
    const [activeProduct, setActiveProduct] = React.useState('');
    const [activeKundesegment, setActiveKundesegment] = React.useState('');
    const [premiumGrowthReqs, setPremiumGrowthReqs] = React.useState([]);
    const [openMessageDialog, setOpenMessageDialog] = React.useState(false);
    const [message, setMessage] = React.useState('Batch-kjøring starter om noen minutter.');

    useImperativeHandle(ref, () => ({
        async saveConstraints() {
            setIsLoadingOrSaving(true);
            let input = {'values': []};
            premiumGrowthReqs.forEach(row => {
                input['values'].push({
                    'PRODUKT': row[0],
                    'VEKST': row[1],
                    'c': row[2],
                    'C': row[3],
                    'risk_lower_quantile': 0.025,
                    'risk_upper_quantile': 0.975,
                })
            });
            get_axios_instance()
                .post('/post_gamma_premium_growth_reqs', input, await getAxiosPostConfig())
                .then(resp => notifyMessage('Vekstinnstillinger lagret.'))
                .catch((error) => {
                    handleError(error, notifyMessage);
                })
                .finally(() => {
                    setIsLoadingOrSaving(false);
                });
        },
        async restoreConstraints() {
            return; // Not yet implemented
        },
        async downloadData(rawData) {
            setIsLoadingOrSaving(true);
            get_axios_instance()
                .get('/download_optimized_premiums_data?batch_job_id=' + selectedBatchJobs[0] + '&raw_data='+rawData, await getAxiosPostConfig()) // TODO: What if none selected?
                .then((response) => {
                    var a = window.document.createElement('a');
                    a.href = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
                    a.download = `optimal_premiums.csv`;

                    // Append anchor to body.
                    document.body.appendChild(a);
                    a.click();

                    // Remove anchor from body
                    document.body.removeChild(a);
                }).catch((error) => {
                    handleError(error, notifyMessage);
                    setIsLoadingOrSaving(false);
                }).finally(() => setIsLoadingOrSaving(false)
            );
        }
    }));

    const handleCloseMessageDialog = () => {
        setOpenMessageDialog(false);
    };

    const handleProductChange = (event) => {
        setActiveProduct(event.target.value);
    };

    const handleKundesegmentChange = (event) => {
        setActiveKundesegment(event.target.value);
    }

    const generateGraphs = () => {
        update_plots(selectedBatchJobs, setIsLoadingOrSaving, activeProduct, activeKundesegment, notifyMessage);
    };

    const handleBatchJobSelectionChange = async (allRowsSelected) => {
        let jobIds = [];
        let products = ['NULL_FILTER'];
        allRowsSelected.forEach(row => {
            const dataRow = batchJobsData[row.dataIndex];
            jobIds.push(dataRow[0]); // First entry is the job id
            for (const prod of dataRow[6].split(', ')) {
                products.push(prod.split(' ')[0]);
            }
        });
        setProducts([...new Set(products)]);
        setActiveProduct('NULL_FILTER');
        setActiveKundesegment('NULL_FILTER');
        setSelectedBatchJobs(jobIds);
    };

    const handleBatchJobDelete = async (batchJobId, doDelete) => {
        const headers = await getAxiosPostConfig();
        if (doDelete) {
            const resp = await get_axios_instance().post('/delete_gamma_batch_job_output', {
                'batch_job_id': batchJobId
            }, headers);
        }        
        await getBachJobIds(); // Update table
        update_plots([], setIsLoadingOrSaving); // Update plots (empty)
    }

    const notifyMessage = (message) => {
        if (message) {
            setMessage(message);
            setOpenMessageDialog(true);
        }
    }

    const getConstraints = () => {
        let constraints = {};
        premiumGrowthReqs.forEach(row => {
            constraints[row[0]] = Number(row[1]); // TODO: Move this conversion logic to better place?
        });
        return constraints;
    }
    
    const getSpreadLower = () => {
        let spreadLower = {};
        premiumGrowthReqs.forEach(row => {
            spreadLower[row[0]] = Number(row[2]);
        })
        return spreadLower;
    }

    const getSpreadUpper = () => {
        let spreadUpper = {};
        premiumGrowthReqs.forEach(row => {
            spreadUpper[row[0]] = Number(row[3]);
        })
        return spreadUpper;
    }
    
    const runBatchJob = async (products, months, startDay, productsStrict, excludeBonusChange, singelKunder, jobName, jobDescription, optimizer, initCondition, constraintType) => {
        const input = {
            'filters': {
                'products': products,
                'months': months,
                'products_strict': productsStrict,
                'exclude_bonus_change': excludeBonusChange,
                'start_day': startDay
            },
            'algorithms': {
                'optimizer': optimizer,
                'init_condition': initCondition,
                'constraint_type': constraintType
            },
            'constraints': getConstraints(),
            'init': {
                'spread': {
                    'c': getSpreadLower(),
                    'C': getSpreadUpper()
                }
            },
            'meta': {
                'description': jobDescription,
                'name': jobName
            }
        };
        if (singelKunder) {
            input['filters']['num_avtaler'] = 1;
        }

        const headers = await getAxiosPostConfig();
        await get_axios_instance().post(
            '/run_gamma_batch_job', input, headers
        ).then(resp => {
            notifyMessage('Batch-kjøring starter om noen minutter.');
            setOpenMessageDialog(true);
        }).catch(error => {
            handleError(error, notifyMessage);
        });
    }

    const getDetailsTable = (inputMapping, productList) => {
       
        const optimizer = 'algorithms' in inputMapping ? inputMapping['algorithms']['optimizer'] : 0;
        var tableString = 'Optimeringsbibliotek: ' + (optimizer == 0 ? 'SciPy' : 'NLOpt');

        const initCondition = 'algorithms' in inputMapping ? inputMapping['algorithms']['init_condition'] : 'flat_adjusted';
        var initConditionFormatted = '';
        switch (initCondition) {
            case 'flat_adjusted':
                initConditionFormatted = 'Flat økning justert for prissensitivitet';
                break;
            case 'risk_adjusted':
                initConditionFormatted = 'Flat økning justert for relativ risiko';
                break;
            case 'flat':
                initConditionFormatted = 'Flat økning';
                break;
            case 'simulated':
                initConditionFormatted = 'Simulert premieøkning';
                break;
            default:
                initConditionFormatted = 'Ukjent';
                break;

        }
        tableString += '\nInitialbetingelse: ' + initConditionFormatted;

        const constraintType = 'algorithms' in inputMapping ? inputMapping['algorithms']['constraint_type'] : 1;
        tableString += '\nVekstberegning: ' + (constraintType  == 0 ? 'Avgangssensitiv' : 'Absolutt');
        
        return tableString;
    }

    const getBachJobIds = async () => {
        const headers = await getAxiosPostConfig();
        let rows = [];
        await get_axios_instance().get('/get_gamma_batch_jobs', headers)
            .then(resp => {
                for (const entry of resp.data) {
                    var months = JSON.parse(entry['input'])['filters']['months'];
                    var months_format = months != null ? months.join(', ') : '';
                    var version = JSON.parse(entry['input'])['version'];
                    var version_format = version != null ? version : '';
                    var products = JSON.parse(entry['input'])['filters']['products']
                    var products_strict = JSON.parse(entry['input'])['filters']['products_strict'];
                    var products_format = products != null ? products.join(', ') : ''
                    if (products_format !== '' && products_strict != false && products_strict != null) {
                        products_format += ' (eksklusivt)';
                    }
                    rows.push([
                        entry['JOB_ID'],
                        JSON.parse(entry['input'])['meta']['user'],
                        format(new Date(entry['date']), 'yyyy-MM-dd, H:mm'),
                        <div>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                            >
                                <Typography>{JSON.parse(entry['input'])['meta']['name']}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {JSON.parse(entry['input'])['meta']['description']}
                                </Typography>
                            </AccordionDetails>
                            </Accordion>
                        </div>,
                        version_format,
                        entry['status'],
                        products_format,
                        months_format,
                        <div>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                            >
                                <Typography>Trykk for detaljer</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {getDetailsTable(JSON.parse(entry['input']), products)}
                                </Typography>
                            </AccordionDetails>
                            </Accordion>
                        </div>,
                        entry['time'],
                        entry['remaining'],
                        entry['db_table']
                    ]);
                }
            }).catch(error => {
                handleError(error, notifyMessage);
        });
        setBatchJobsData(rows);
    };

    useEffect(() => {        
        async function getPremiumGrowthReqs() {
            const headers = await getAxiosPostConfig();
            const resp = await get_axios_instance().get('/get_gamma_premium_growth_reqs', headers);
            const tmpPremiumGrowthReqs = [];
            resp.data.forEach((value, index) => {
                tmpPremiumGrowthReqs.push([
                    resp.data[index]['PRODUKT'],
                    resp.data[index]['VEKST'],
                    resp.data[index]['c'] ?? '0.015',
                    resp.data[index]['C'] ?? '0.1',
                ])
            })
            setPremiumGrowthReqs(tmpPremiumGrowthReqs);
        };        
        getBachJobIds();
        getPremiumGrowthReqs();
     }, [])    

    return (
        <div>
            <div>                
                <div className={classes.horizontal}>                
                    <RunBatchJobDialog className={classes.button} onConfirm={runBatchJob}/>
                    <ConstraintsTable className={classes.button} data={premiumGrowthReqs} onInputChanged={setPremiumGrowthReqs}/>  
                </div>
                <div className={classes.includeWhitespaces}>
                    <BatchJobsTable
                        data={batchJobsData}
                        handleBatchJobSelectionChange={handleBatchJobSelectionChange}
                        handleBatchJobDelete={handleBatchJobDelete}
                        handleReloadData={getBachJobIds}
                    />
                </div>
                <div className={classes.horizontal}>
                    
                <FormControl className={classes.formControl}>
                    <InputLabel id='select-product'>Produkt</InputLabel>
                    <Select
                        labelId='select-product-label'                    
                        disabled={isLoadingOrSaving}
                        id='select-product'
                        value={activeProduct}
                        onChange={handleProductChange}
                    >
                        {
                            products.map(prod => {
                                return <MenuItem value={prod}>{prod}</MenuItem>;
                            })
                        }                    
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel id='select-kundesegment'>Kundesegment</InputLabel>
                    <Select
                        labelId='select-kundesegment-label'                    
                        disabled={isLoadingOrSaving}
                        id='select-kundesegment'
                        value={activeKundesegment}
                        onChange={handleKundesegmentChange}
                    >
                        {
                            kundesegmenter.map(segment => {
                                return <MenuItem value={segment}>{segment}</MenuItem>;   
                            })
                        }
                                         
                    </Select> 
                </FormControl>
                <Button onClick={generateGraphs} variant="contained">Generer nye grafer</Button>

                </div>
                <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                }}>
                        {isLoadingOrSaving && <CircularProgress />}
                </div>
            </div>
            <div className={classes.horizontal}>
                {
                    [...Array(10).keys()].map(i => {
                        return <div id={'gammaPlot' + i}/>;
                    })
                }        
            </div>
            <MessageDialog openMessageDialog={openMessageDialog} handleCloseMessageDialog={handleCloseMessageDialog} message={message} />
            
        </div>
    );
});
export default PriceOptimization;