import MUIDataTable from "mui-datatables";
import React from 'react';

import {Tooltip, IconButton} from '@material-ui/core';
import { Refresh } from "@material-ui/icons";

export default function BatchJobsTable(props) {
    let data = props.data;
    const columns = [
        {
            name: 'Batchjobb-ID',       
        },
        {
            name: 'Kjørt av'
        },
        {
            name: 'Tidspunkt',
            options: { sortDirection: 'asc' }
        },
        {
            name: 'Navn/beskrivelse'
        },
        {
            name: 'Kodeversjon'
        },
        {
            name: 'Status'
        },
        {
            name: 'Produkter'
        },
        {
            name: 'Måneder'
        },
        {
            name: 'Betingelser'
        },
        {
            name: 'Tid brukt (s)'
        },
        {
            name: 'Gjenstående tid (s)'
        },
        {
            name: 'I databasetabell?'
        }
    ];

    const ReloadButton = () => (
        <Tooltip disableFocusListener title="Last inn data på ny">
          <IconButton onClick={() => props.handleReloadData()}>
            <Refresh />
          </IconButton>
        </Tooltip>
      );

    const rowOptions = {
        onRowsSelect: (currentRowsSelected, allRowsSelected) => {
            props.handleBatchJobSelectionChange(allRowsSelected);
        },
        onRowsDelete: (e) => {
            for (const entry of e.data) {
                const dataRow = data[entry.dataIndex];
                const doDelete = window.confirm('Er du sikker på at du vil slette resultatene fra batch-kjøring ' + dataRow[0] + '?');
                props.handleBatchJobDelete(dataRow[0], doDelete);
            }  
        },        
        customToolbar: ReloadButton,
        rowsPerPage: 4,
        rowsPerPageOptions: [4, 8, 12],
    };


    return (
        <MUIDataTable
            title={"Batch-jobber"}
            data={data}            
            columns={columns}
            options={rowOptions}
        />

    )
}