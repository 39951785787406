
export default function format_number_percentage(input) {
  return parseFloat(input).toFixed(2) + '%';
}

export function format_number_currency(input) {
  return input.toFixed(0) + ' kr';
}
export function format_number_integer(input) {
  return parseInt(input);
}

export function format_number_significant(input, significant_figures = 0) {
  return parseFloat(input).toFixed(significant_figures)
}

export function get_premium_change_string(old_premium, new_premium) {
  let result = format_number_currency(new_premium);
  if (old_premium < new_premium) {
      result += ' (\u25b2' + format_number_percentage((new_premium-old_premium)/old_premium * 100.0) + ')'
  }
  else {
      result += ' (\u25bc' + format_number_percentage((new_premium-old_premium)/old_premium * 100.0) + ')'
  }
  return result;
}

export function get_number_change_string(old_number, new_number) {
  let result = new_number.toString();
  if (old_number < new_number) {
      result += ' (\u25b2' + format_number_percentage((new_number-old_number)/old_number * 100.0) + ')'
  }
  else {
      result += ' (\u25bc' + format_number_percentage((new_number-old_number)/old_number * 100.0) + ')'
  }
  return result;
}